import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import '../Components/Gallery.css';
import img1 from '../images/blue1.jpg';
import img2 from '../images/blue3.PNG';
import img3 from '../images/eyes.jpg';
import img4 from '../images/vclassf.JPG';
import img5 from '../images/blue2.JPG';
import img6 from '../images/red1.JPG';
import img7 from '../images/red2.JPG';
import img8 from '../images/sclass.png';
import img9 from '../images/vclassb.JPG';
import img10 from '../images/vclassbl.jpg';
import img11 from '../images/vclassfl.jpg';




const Gallery = () =>{
let data =[
    {
        id: 1,
        imgSrc: img1,
    },
    {
        id: 2,
        imgSrc: img2,
    },
    {
        id: 3,
        imgSrc: img3,
    },
    {
        id: 4,
        imgSrc: img4,
    },
    {
        id: 5,
        imgSrc: img5,
    },
    {
        id: 6,
        imgSrc: img6,
    },
    {
        id: 7,
        imgSrc: img7,
    },
    {
        id: 8,
        imgSrc: img8,
    },
    {
        id: 9,
        imgSrc: img9,
    },
    {
        id: 10,
        imgSrc: img10,
    },
    {
        id: 11,
        imgSrc: img11,
    },
]
const[model, setModel] = useState(false);
const[tempimgSrc, setTempImgSrc] = useState('');
const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
}
return(
    <>
    <div className={model? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon onClick={() => setModel(false)} />
    </div>
    <div className='gallery'>
        {data.map((item, index)=>{
        return(
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                 <img className="gallery-img "src={item.imgSrc} />
         </div>
        )
        })}
        </div>
        </>
    )
}

export default Gallery;
