import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Home';
import Cars from './Cars';
import Contact from './Contact';
import Error from './Error';
import Footer from "./Components/Footer";
import About from "./About";
import PrivacyPolicy from "./PrivacyPolicy";


export default function App() {
  return (<>
    <div className="nav">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="Cars" element={<Cars />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="About" element={<About />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
    </div>
   

    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
