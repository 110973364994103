import React from "react";
import { Outlet, Link } from "react-router-dom";
import '../App.css';
import "./cards.css";

function CardItems() {
  return ( 
    <>
   
    <section className="car-wrapper">
    <div className="car-cards">
      <div className="car-info">
        <img className='car-img' src='./images/v-class.webp' alt='v-class van'></img>
        <h2 className='card-title'>Mercedes V-Class</h2>
        <div className="rates">
          <div className="time"> Hourly rate (4 hours within London)</div>
          <div className="price">£55</div>
        </div>
        <div className="rates">
          <div className="time"> Daily rate (12 hours)</div>
          <div className="price">£500</div>
        </div>
        <div className=" small">
          <div className="time"> Prices subject to VAT</div>
        </div>
        <Link className='card-button' to="./Contact">
        Book Now
        </Link>
      </div>

      <div className="car-info">
        <img className='car-img' src='./images/s-class.webp' alt='v-class van'></img>
        <h2 className='card-title'>Mercedes E/S-Class</h2>
        <div className="rates">
          <div className="time"> Hourly rate (4 hours within London)</div>
          <div className="price">£45</div>
        </div>
        <div className="rates">
          <div className="time"> Daily rate (12 hours)</div>
          <div className="price">£400</div>
        </div>
        <div className="small">
          <div className="time"> Prices subject to VAT</div>
        </div>
        <Link className='card-button' to="./Contact">
        Book Now
        </Link>
      </div>

      <div className="car-info">
        <img className='car-img' src='./images/v-class.webp' alt='v-class van'></img>
        <h2 className='card-title'>Mercedes Jet-Class</h2>
        <div className="rates">
          <div className="time"> Hourly rate (4 hours within London)</div>
          <div className="price">£65</div>
        </div>
        <div className="rates">
          <div className="time"> Daily rate (12 hours)</div>
          <div className="price">£650</div>
        </div>
        <div className="small">
          <div className="time"> Prices subject to VAT</div>
        </div>
        <Link className='card-button' to="./Contact">
        Book Now
        </Link>
      </div>
    </div>
  </section>
  <Outlet />

  </>
   );
}

export default CardItems;