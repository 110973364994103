import './Form.css';

import { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

const Result = () => {
    return (
        <div className="row">
        <p> Submitted Successfuly!
        </p>
        <Link to="/" className='go-back'>Go back to homepage?</Link>
        </div>
      );
}
  
 

const Form = (e) => {

    const [result, showResult] = useState(false);
    const form = useRef()
    const [data, setData] = useState({name: "", email: "", phone: "", pickup: "", dropoff: "", date: "", time: "", SClass: "", Marketing:"" });


    const handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value})

    }
    

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_uvrjajy', 'template_da6x4ss', form.current, 'AxmeJVpFBLD_byakn')
          .then((result) => {
              console.log(result.text);

          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          showResult(true);
      };

      useEffect(() => {
        document.title= 'MCVIPCARS | Contact'
      },[]);
    
      const [showButton, setShowButton] = useState(true);

      const toggleButton = () => {
        setShowButton(!showButton);
      };


    return ( 
        <div className='hero-container-form'>
        <div className="flex-container">
            <div className="flex-child title">
            
            {/* <img className='contact-title' src='./images/logo-img-icon.png' alt='logo image icon' /> */}
            <h1 className='contact-title'>Book Your Luxury Travel Now</h1>
            {/* <h2 className="contact-sub">
            Award winning chauffeuring service 
            </h2> */}
            </div>
            {!result  &&
        <div className="flex-child">
        <form ref={form} onSubmit={sendEmail} className="contact-form">
        <h1 className="contact--">Contact <span>Here</span></h1>
        <input type="text" name="name" id="" onChange={handleChange} value={data.name} placeholder="eg. John Doe" required/>
        <input type="phone" name="phone" id="" onChange={handleChange} value={data.phone} placeholder="eg. +44 7777777" required/>
        <input type="email" name="email" id="" onChange={handleChange} value={data.email} placeholder="eg. John@email.com" required/>
        <div className="date-time">
        <input className="date-option" type="text" name="pickup" id="" onChange={handleChange} value={data.pickup} placeholder="Pick-up location" required/>
        <input type="text" name="dropoff" id="" onChange={handleChange} value={data.dropoff} placeholder="Drop-off location" />
        </div>
        <div className="date-time">
        <input className="date-option" type="date" name="date" id="" onChange={handleChange} value={data.date} placeholder="Date" required/>
        <input className="time-option" type="time" name="time" id="" onChange={handleChange} value={data.time} placeholder="Time" required/>
        </div>
        <p className='vehicle-prompt'>please select a preffered vehicle</p>
        <div className="car-checkbox">
          
        <label>
        <p className='checkbox-names'>S Class</p>
        <input type="checkbox" name="SClass" id="" onChange={handleChange} value={"S class "} placeholder="S class" />
        <img className='car-img options' src='./images/s-class.webp' alt='v-class van'></img>
        </label>
        <label>
        <p className='checkbox-names'>V Class</p>
        <input type="checkbox" name="VClass" id="" onChange={handleChange} value={"V class"} placeholder="V class" />
        <img className='car-img options' src='./images/v-class.webp' alt='v-class van'></img>
        </label>
        <label>
        <p className='checkbox-names'>Jet Class</p>
        <input type="checkbox" name="JClass" id="" onChange={handleChange} value={"J class"} placeholder="Jet class" />
        
        <img className='car-img options' src='./images/v-class.webp' alt='v-class van'></img>

        </label>
        </div>
        {/* <textarea type="message" name="message" id="" cols="38" rows="10" onChange={handleChange} value={data.message} placeholder="add further details "/> */}
        <label >
        <p className='checkbox-names'>If you wish to not be contacted for latest news and offers please tick the box below</p>
        <input className='marketing' type="checkbox" name="Marketing" id="" onChange={handleChange} value={"NO"} placeholder="Marketing" />
        <Link className='form-tc' to="./PrivacyPolicy">Terms & Conditions | privacy </Link>
        </label>
        <button type="submit" onClick={toggleButton}>Send</button> 
        <div>
            
        </div>
        </form>
        </div>}
        {result ? <Result /> :null} 
        </div>
        </div>
     ); 
}
 
export default Form;