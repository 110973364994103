import React from 'react';
import '../App.css';
import { Outlet, Link } from "react-router-dom";
import { Button } from './Button';
import './HeroSection.css';
import './cards.css';
import CardItems from "./CardItems";
import Form from './form';

function HeroSection() {
    return ( 
    <>
    <section className='contact-wrapper'>
</section>
  
        <div className="home">
         <div className='hero-container'>
      <video  webkit-playsinline={true} src='/videos/Untitled.mov' autoPlay loop muted playsInline></video>
      {/* <h1 className="homeTitle">Get the car service that meets your needs</h1>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          BOOK NOW
        </Button>
      </div> */}

      <Form></Form>
    </div>
        
     
     </div>

     <section className='services'>
      <div className="service-wrapper">
        <div className="service-cards">
          <img className='boldy' src='./images/boldy.jpg' alt='bold man opening car door'></img>
          <h2 className='services-title'>Assisted Driving</h2>
          <p className='services-text'> Have our proffessional staff support you with your travel needs </p>
          <Link className='booking-link' to="./Contact">
        Book Now
        </Link>
        </div>
      </div>
      <div className="service-wrapper">
        <div className="service-cards">
          <img className='boldy' src='./images/Airport-car.jpeg' alt='bold man opening car door'></img>
          <h2 className='services-title'>Airport Transfers</h2>
          <p className='services-text'>Bringing you convenience with your trip to the airport</p>
          <Link className='booking-link' to="./Contact">
        Book Now
        </Link>
        </div>
      </div>
      <div className="service-wrapper">
        <div className="service-cards">
          <img className='boldy' src='./images/corp_trans.webp' alt='bold man opening car door'></img>
          <h2 className='services-title'>Corperate Transfers</h2>
          <p className='services-text'>Allow us to support your travel needs to corperate functions </p>
          <Link className='booking-link' to="./Contact">
        Book Now
        </Link>
        </div>
      </div>
      <div className="service-wrapper">
        <div className="service-cards">
          <img className='boldy' src='./images/city.jpeg' alt='bold man opening car door'></img>
          <h2 className='services-title'>International Transfers </h2>
          <p className='services-text'>Ensure your international trips are comfortable with our luxurious vehicles</p>
          <Link className='booking-link' to="./Contact">
        Book Now
        </Link>
        </div>
      </div>
     </section>
  <section className="features">
    <div className="features-content-slogan">
      <h1 className="features-title"> Premier Travel Solutions Tailored for You</h1>
      <p className="features-text"> Delivering Award-Winning Service and Crafting Tailored Luxury Travel Solutions to Suit Your Requirements.</p>
    </div>

  </section>
  <Outlet />
  <CardItems />
  <section className="features">
    <div className="features-content">
      <i class="fa-solid fa-award key-points"></i>
      <p className="features-text"> Award winning service</p>
    </div>
    <div className="features-content">
      <i class="fa-solid fa-plane key-points"></i>
      <p className="features-text"> VIP meet and assisst at all UK airports</p>
    </div>
    <div className="features-content">
      <i class="fa-solid fa-shield-halved key-points"></i>
      <p className="features-text"> Security and armoured vehicle</p>
    </div>
    <div className="features-content">
      <i class="fa-solid fa-microchip key-points"></i>
      <p className="features-text"> High tech vehicles for top of the line comfort</p>
    </div>
    <div className="features-content">
    <i class="fa-brands fa-sketch key-points"></i>
      <p className="features-text"> Premium supplementary services</p>
    </div>
  </section>

     </>
     );
}

export default HeroSection;