import CardItems from "./Components/CardItems";
import React from 'react'
import Gallery from "./Components/Gallery";
function Cars() {
    return ( 
        <>
         <div className="hero-img">
      <img className="vclass-hero" src="./images/hero-img.jpeg"></img>
    </div>
        <CardItems />
        <section className="features">
    <div className="features-content-slogan">
      <h1 className="features-title"> High spec vehicles for your travel needs</h1>
      <p className="features-text">Choose from a range of our vehicles, tailored and designed to provide you with maximum comfort and security for all of your travel needs.</p>
    </div>

  </section>
        <Gallery />
    <div className="custom-build-title">
        <h1>Build your own custom Jet-Class in 3 easy steps</h1>
    </div>
    <section className="features custom-features">
    <div className="features-content circle">
    <div className="custom-steps-text">
      <h2 className="custom-steps step-title">Step 1:</h2>
      <h1 className="custom-steps">Contact us</h1>
      <p className="features-text custom-steps"> Reach out to buld your own custom Jetclass! Just the way you want it.</p>
    </div>
    </div>
    <div className="features-content circle">
    <div className="custom-steps-text">
    <h2 className="custom-steps step-title">Step 2:</h2>
    <h1 className="custom-steps">Vision & Plan</h1>
      <p className="features-text custom-steps">Our team will take the time to discover clients needs and wishes in order to help with building your dream JetClass.</p>
    </div>
    </div>
    <div className="features-content circle">
     <div className="custom-steps-text">
    <h2 className="custom-steps step-title">Step 3:</h2>
    <h1 className="custom-steps">Recieve your car</h1>
    <p className="features-text custom-steps">There will be a 60 days waiting period while our team take the time and precission necessary to build your car just as you invisioned</p>
    </div>   
    </div>


  </section>
 
  <div className="custom-build-title">
  <a aria-label="Chat on WhatsApp" href="https://wa.me/447383989729"> 
    <img alt="Chat on WhatsApp" src="./images/WhatsAppButtonGreenMedium.svg" className="whatsapp"/>
    </a>
  </div>

        </>
     );
}

export default Cars;