import React, {useEffect} from 'react';
import './About.css';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WifiIcon from '@mui/icons-material/Wifi';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AOS from 'aos'
import 'aos/dist/aos.css'



export default function About() {
  useEffect(() => {
    AOS.init({duration: 2000});
  }, []);
  return (
    <div className='about-wrapper'>
      <div className="hero-section">
      <img className='suit-img' src='images/IMG_0522.jpg' alt='man wearing suit and logo as head' />
      </div>
      <div className='info-wrapper'>
        <div className="titles">
        <h1 className='info-title'>
            Who is MC VIP CARS?
          </h1>
        </div>
        <div className="info" data-aos="fade-up"
     data-aos-duration="2000">
        <div className="info-left">
          <h1 className='info-title  age'  data-aos="fade-up">
            1999
          </h1>
        </div>
        <div className="info-right">
          <p className='info text' >
          MC VIP CARS is an inherited family-based
          company established in 1999 London, UK. We
          offer a professional and discreet chauffeuring
          service in all areas; Corporate, Events, Private Jet
          Terminals, Airport Transfers, Weddings, Video
          Shoots or any Leisure trip in and out of London.
          We have a great reputation with vast experience,
          offering our clients a professional and special
          transportation service as they board in elegance.

          </p>
        </div>
        </div>
        <div className="titles" data-aos="fade-up"
     data-aos-duration="2000">
        <h1 className='info-title'>
            WHY CHOOSE MC VIP CARS?
          </h1>
        </div>
        <div className="info"data-aos="fade-up"
     data-aos-duration="2000">
        <div className="info-left">
        <p className='info text'>
          PROFESSIONALISM: MC chauffeurs are constantly
          dressed professionaly; in suit & tie. Our vehicles are always
          washed and cleaned prior to every job. The chauffeurs are
          always well groomed ready to transport you in style.
          LUXURY PRIVATE CAR EXPERIENCE: Our vehicles
          are less than 4 years old, regularly serviced and equipped
          with executive packages, on-board Wi-Fi, complimentary
          water and sweets. Alcohol service is available upon request
          for addition price.

          ABOVE AND BEYOND: We aim to not only meet but exceed your 
          expectations and build a long-term professional relationship 
          with our clients. Our chauffeurs work around the clock to ensure
           your journey is as pleasant and hassle-free as possible.
          </p>
          
        </div>
        <div className="info-right">
        <img className='boldy' src='images/chauffeur-.jpeg' alt='professional mamn opening car door '/>
        </div>
        </div>
        <div className="info" data-aos="fade-up"
     data-aos-duration="2000">
          <div className="info-left">
          <HandshakeIcon />
          </div>
        <div className="info-right">
        <p className='info text'>
        OUTSTANDING CUSTOMER SERVICE: Personalised
        service combined with detailed attention and personalised
        style creates the ultimate luxury chauffeur experience in
        London. We are content to cooperate with your personal
        assistant to ensure your program is planned efficiently and
        effectively.
        CONFIDENTIALITY: MC chauffeurs are selected, chosen
        and nominated for the outstanding customer service they
        provide. As we believe our chauffeurs represent our clients,
        we ensure their appearance is maintained to the highest
        standards.
        ALWAYS ON TIME: MC team believes punctuality is the
        most essential area for our clients, this is why we ensure all
        of our vehicles arrive I5 minutes before the booking time as
        flights are always monitored to track client's status.
          </p>
          
        </div>
        </div>
        <div className="titles" data-aos="fade-up"
     data-aos-duration="2000">
        <h1 className='info-title'>
        Additional Benefits of MC VIP CARS!
          </h1>
        </div>
        <div className="info" data-aos="fade-up"
     data-aos-duration="2000">
        <div className="info-left">
        <p className='info text'>
        24 HOURS ON DEMAND SUPPORT: MC VIP
        CARS operates 24/7 365 days a year, our management
        board is present at all times regarding client enquires.
        ONBOARD WIFI: Need to be online while you're
        on the move? Don't worry. You can work, surf and
        check vour email via free Wi-Fi we provide within our
        vehicles to ensure you stay connected.

        ROOM FOR 4,5,6,7,8: An active feet containing
        several vehicles allowing us to provide transportation
        for our clients needs, whether you need a small saloon
        vehicle or a big MPV vehicle to meet your request.
          </p>
          
        </div>
        <div className="info-right">
        <EventAvailableIcon />
        <WifiIcon />
        <GroupAddIcon />
        </div>
        </div>
      </div>
    </div>
  )
}
