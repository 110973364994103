import { Outlet, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import './Navbar.css';

const Navbar = () => {
    const [click, setClick] = useState(false);

    const handleCLick = () => setClick(!click);

    const closeMiniMenu = () => setClick(false);
    const [button, setButton] = useState(true);


    return ( 
        <>
        <nav className="navbar">
            <div className="navbar-container">
            <Link to="/" className="navbar-logo"><img src="images/logo-img.png" alt="mcvipcars logo"/> </Link>
                <div className="menu-icon" onClick={handleCLick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <div className={click ? 'nav-menu active' : 'nav-menu'}>
                    <div className="nav-item">
                        <Link to="/" className="nav-links" onClick={closeMiniMenu}>
                            Home
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link to="./Cars" className="nav-links" onClick={closeMiniMenu}>
                           Cars
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link to="./About" className="nav-links" onClick={closeMiniMenu}>
                           About 
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link to="./Contact" className="nav-links" onClick={closeMiniMenu}>
                            Book Now
                        </Link>
                    </div>
                

                    
                </div>
                
                </div>
        </nav>
     <Outlet /></>


     );
}
 
export default Navbar;

