import React from 'react'
const PrivacyPolicy = () => {
    return ( 
        <>
        <p>
        **Terms of Service and Privacy Notice for MCVIPCars**
<br></br><br></br>
**Terms of Service:**
<br></br>
**1. Our Services:**
   a) MCVIPCars offers a range of services, including VIP Transfers, Close Protection, Assisted Driving, and Asset Protection.
   b) Additionally, Personal Driving and Protection services are provided to meet individual needs.
   <br></br>
**2. User Interaction:**
   a) Users, by submitting information through forms and data tracking, grant MCVIPCars the permission to collect and utilize data for contact and marketing purposes.
   <br></br>
**3. Data Collection:**
   a) MCVIPCars actively collects personal data and browsing information to improve user experience.
   b) Data is obtained through user-filled forms and the use of Google Analytics 4 (GA4).
   <br></br>
**4. Payments:**
   a) MCVIPCars does not store payment information. Only personal data is used for invoicing purposes.
   <br></br>
**5. Intellectual Property:**
   a) All content featured on the MCVIPCars website, encompassing text and images, is the proprietary property of MCVIPCars. For intellectual property inquiries, contact us at mcvipcars@hotmail.com.
   <br></br>
**6. User Responsibilities:**
   a) Users are expected to utilize the website strictly for its intended purpose.
   b) Adherence to the non-compete agreement is mandatory for all users.
   <br></br>
**7. Liabilities and Disclaimers:**
   a) MCVIPCars assumes no responsibility for the accuracy, completeness, or timeliness of information provided on the website.
   b) The services are offered "as is," and MCVIPCars disclaims any warranties, whether express or implied.
   <br></br>
**8. Termination of Services:**
   a) MCVIPCars reserves the right to terminate services under specified conditions, including but not limited to breaches of terms, fraudulent activities, or misuse of the website.
   <br></br>
**9. Client Awareness:**
   a) Clients are required to have a clear understanding of the client-driver and car relationship.
   b) All drivers and vehicles must adhere to operating license rules and regulations.
   <br></br>
**10. Updates to Terms:**
   a) MCVIPCars regularly updates its terms to align with business needs and regulatory changes.
   b) Users will be promptly informed of any alterations to the terms via designated communication channels.
   <br></br>
---

**Privacy Notice:**
<br></br>
**Data Collection and Use:**
MCVIPCars collects personal information, including names, email addresses, and contact details, to provide and enhance services. This data may be utilized for contact, marketing, and service-related purposes as outlined in the privacy policy.
<br></br>
**Cookies and Tracking Technologies:**
MCVIPCars employs cookies and tracking technologies to enhance user experience and gather insights on website usage. Users have the option to disable cookies in their browser settings, recognizing this may impact their ability to use certain features.
<br></br>
**Security of Personal Information:**
MCVIPCars employs reasonable measures to secure personal information. However, users acknowledge that no internet transmission is entirely secure, and transmission of personal information is at the user's own risk.
<br></br>
**Third-Party Services:**
MCVIPCars may engage third-party services, including analytics providers, which may employ cookies and tracking technologies. These services collect data about users' online activities over time and across different websites.
<br></br>
**Changes to Privacy Notice:**
MCVIPCars reserves the right to modify this privacy notice. Users are encouraged to regularly review this notice for updates. Continued use of the website following changes implies acceptance of the revised privacy terms.
<br></br>
**Governing Law and Jurisdiction:**
The terms and conditions, along with the privacy notice, are governed by the laws of the United Kingdom. Any disputes are subject to the exclusive jurisdiction of the United Kingdom courts.
</p></>
     );
}
 
export default PrivacyPolicy;