
import './Footer.css';
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { useState, useRef } from 'react';



const Result = () => {
    return (
        <div className="row">
        <p> Submitted Successfuly!
        </p>
        </div>
      );
}
 

function Footer() {
    const subscribe = useRef()
    const [result, showResult] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_g0c4mu7', 'template_frsh4ww', subscribe.current, 'AxmeJVpFBLD_byakn')
          .then((result) => {
              console.log(result.text);

          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          showResult(true);
      };

    return ( <div className="footer-container">
        {/* <div className="footer-subscription">
            <p className="footer-subscription-heading">
                join the family and recieve our deals
            </p>
            <p className="footer-subscription-text">
                you can unsubscribe at any time</p>   
                <div className="input-area">
                    <form ref={subscribe} onSubmit={sendEmail}>
                        <input type="email" name='email' placeholder="your email" className="footer-input" />
                        <button type='submit' >Subscribe </button>
                        
                    </form>
                </div>
        </div> */}
        <div className="footer-links">
            <div className="footer-link-wrapper">
                <div className="footer-link-items">
                    <h2>Get to know us</h2>
                    <Link to="./">Home </Link>
                    <Link to="./Cars">Cars</Link>
                    <Link to="./Contact">Get a quote </Link>
                    <Link to="./PrivacyPolicy">Terms & Conditions | privacy </Link>
                </div>
                {/* <div className="footer-link-items">
                    <h2>Services</h2>
                    <Link to="./WebDev">Website developement </Link>
                    <Link to="./WebDesign">Website design </Link>
                    <Link to="./ContentC">Content Creation </Link>
                </div> */}
               
        </div>
        
        </div>
        <div className="social-media">
            <div className="social-media-wrap">
                <div className="footer-logo">
                    <Link to='/' className="social-logo">
                        MCVIPcars <i className="fab fa-type03">

                        </i>
                    </Link>

                </div>
                <small className="website-rights">MCVIPcars © 2020 </small>
                <small className="website-rights">powered by Webylonia.co.uk </small>
                <div className="social-icons">
                    <a href='https://instagram.com/webylonia?igshid=Y2IzZGU1MTFhOQ=='
                    className="social-icon-link instagram"
                    target="_blank"
                    aria-label='Instagram'
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href='https://www.tiktok.com/@webylonia?_t=8e485XbJvIJ&_r=1'
                    className="social-icon-link tiktok"
                    target="_blank"
                    aria-label='Tiktok'
                    >
                        <i className="fab fa-tiktok"></i>
                    </a>
                    <i class="fa-brands fa-paypal social-icon-link"></i>
                    <i class="fa-brands fa-cc-visa social-icon-link"></i>
                    <i class="fa-brands fa-cc-mastercard social-icon-link"></i>
                </div>
            </div>
            
        </div>
    </div> );
}

export default Footer;