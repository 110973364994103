import { useState, useEffect } from "react";
import './App.css';
import React from 'react';
import HeroSection from "./Components/HeroSection";
import CardItems from "./Components/CardItems";




function Home ()  {

  useEffect(() => {
    document.title= 'MCVIPcars | Home'
  },[]);

    return ( 
      <> 
      <HeroSection />
      
         </>
        
        
     );
}
export default Home;